.force-update-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .force-update-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .force-update-modal h2 {
    margin: 0 0 15px 0;
    color: #333;
  }
  
  .force-update-modal p {
    margin: 0 0 20px 0;
    color: #666;
  }
  
  .update-button-container {
    margin-top: 20px;
  }
  
  .update-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }